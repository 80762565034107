<template>
    <div>
      <v-app-bar
        app
        color="var(--color-pallet-5)"
        dark
      >
          <v-toolbar-title>
              <router-link
                  :to="getLocalisedUrl('/')">
              <img class="mr-1" src="@/assets/logo.png" height="30"/>
              <v-icon color="var(--color-pallet-1)">mdi-calendar-star</v-icon>
              </router-link>
          </v-toolbar-title>

          <v-spacer></v-spacer>


          <!-- Desktop menu -->
          <div class="d-none d-sm-block">
            <div class="d-inline-block" v-if="!isLoggedIn">
              <router-link
                  v-for="item in menuItemsSecond"
                  :key="item.title"
                  link
                  :to="getLocalisedUrl(item.link)"
                  class="mr-2"
              >
                  {{ item.title }}
              </router-link>
            </div>
            
            <v-menu
                v-else
                bottom
                left
                offset-y
                eager
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  dark
                  text
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon class="mr-2">mdi-account-circle</v-icon> {{ username }}
                </v-btn>
              </template>

              <v-list class="text-right">
                <v-list-item-group v-if="isLoggedIn && userOrganizations">
                  <v-list-item
                      link
                      :to="getLocalisedOrganizationUrl(userOrganizations)"
                  >
                      <v-list-item-content>
                      <v-list-item-title>{{ userOrganizations.name }}</v-list-item-title>
                      </v-list-item-content>
                  </v-list-item>
                  </v-list-item-group>
                  
                  <v-divider/>
                  
                  <profile-menu v-if="isLoggedIn"/>

                  <v-list-item-group v-if="isLoggedIn">
                  <v-divider/>
                  
                  <v-list-item
                      @click="logout"
                      class="logout"
                  >
                      <v-list-item-icon>
                      <v-icon>mdi-logout</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                      <v-list-item-title>{{ $t('common.button.logout') }}</v-list-item-title>
                      </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-menu>

          </div>


          <v-menu
              bottom
              left
              offset-y
              eager
          >
              <template v-slot:activator="{ on, attrs }">
              <v-btn
                  class="d-sm-none d-block"
                  dark
                  icon
                  v-bind="attrs"
                  v-on="on"
              >
                  <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
              </template>


              <v-list class="text-right d-sm-none d-block">
              

              <v-list-item-group v-if="isLoggedIn && userOrganizations">
                  <v-list-item
                  link
                  :to="getLocalisedOrganizationUrl(userOrganizations)"
                  >
                  <v-list-item-content>
                      <v-list-item-title>{{ userOrganizations.name }}</v-list-item-title>
                  </v-list-item-content>
                  </v-list-item>
              </v-list-item-group>
              <v-list-item-group v-else>
                  <v-list-item
                  v-for="(myOrganization, index) in myOrganizations"
                  :key="index"
                  link
                  :to="getLocalisedOrganizationUrl(myOrganization)"
                  >
                  <v-list-item-content>
                      <v-list-item-title>{{ myOrganization.name }}</v-list-item-title>
                  </v-list-item-content>
                  </v-list-item>
              </v-list-item-group>
              
              <v-divider/>
              
              <profile-menu v-if="isLoggedIn"/>

              <v-list-item-group v-if="isLoggedIn">
                  
                  <v-divider/>
                  
                  <v-list-item
                  @click="logout"
                  class="logout"
                  >
                  <v-list-item-icon>
                      <v-icon>mdi-logout</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                      <v-list-item-title>{{ $t('common.button.logout') }}</v-list-item-title>
                  </v-list-item-content>
                  </v-list-item>
              </v-list-item-group>


              <v-list-item-group v-else>
                  <v-list-item
                  v-for="item in menuItemsSecond"
                  :key="item.title"
                  link
                  :to="getLocalisedUrl(item.link)"
                  >
                  <v-list-item-icon>
                      <v-icon>mdi-account-circle</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                      <v-list-item-title>{{ item.title }}</v-list-item-title>
                  </v-list-item-content>
                  </v-list-item>
              </v-list-item-group>
              </v-list>
          </v-menu>
          
        </v-app-bar>


          <!-- Mobile mainMenu -->
          
          <v-main>
            <my-pages-layout/>
          </v-main>

          <v-footer
            padless
            >
            <v-card
                flat
                tile
                class="text-center"
                color="#eee"
                width="100%"
            >
                <!--<v-card-text class=" pt-0">
                Phasellus feugiat arcu sapien, et iaculis ipsum elementum sit amet. Mauris cursus commodo interdum. Praesent ut risus eget metus luctus accumsan id ultrices nunc. Sed at orci sed massa consectetur dignissim a sit amet dui. Duis commodo vitae velit et faucibus. Morbi vehicula lacinia malesuada. Nulla placerat augue vel ipsum ultrices, cursus iaculis dui sollicitudin. Vestibulum eu ipsum vel diam elementum tempor vel ut orci. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.
                </v-card-text>-->

                <v-divider></v-divider>

                <v-card-text>
                <div v-html="$t('layout.footer-text')"/>
                </v-card-text>
            </v-card>
        </v-footer>
    </div>
</template>
<script>

import urlUtil from '@/js/utils/urlUtil'

import MyPagesLayout from '@/components/private/layout/MyPagesLayout'
import ProfileMenu from '@/components/private/layout/ProfileMenu'

export default {
  components: { MyPagesLayout, ProfileMenu },
  name: 'App',
  data () {
    return {
      menuItemsSecond: [
        { 
          title: this.$t('layout.menu.login'),
          link: '/login',
        },
      ]
    }
  },
  computed: {
    isLoggedIn: function() {
      return this.$store.getters['user/isLoggedIn']
    },
    username: function() {
      return this.$store.getters['user/name']
    },
    myOrganizations: function() {
      return this.$store.getters['session/myOrganizations']
    },
    userOrganizations: function() {
      return this.$store.getters['dashboard/myOrganization']
    }
  },
  created: function(){
    if (this.isLoggedIn){
      this.$store.dispatch('user/refreshUserData')
    }
  },
  methods:{
    ...urlUtil,
    logout: function(){
      this.$store.dispatch('user/logout')
    }
  }

};
</script>
<style>
.v-application .home-icon a {
  color: #eee;
  text-decoration: none;
  font-size: 22px;
}
.v-application.as-page .as-header a {
  color: #eee;
  text-decoration: none;
}
.v-application .lang-menu {
  cursor: pointer;
}
.v-application.as-page .as-header .as-organization-link {
  color: var(--color-pallet-1) !important;
  font-weight: 600;
  font-size: 24px
}
.v-application.as-page .as-header .as-organization-link .v-avatar {
  display: inline;
}
</style>