import api from '@/js/services/axios'

export default {

  sendMessage (message) {
    return new Promise((resolve, reject) => {
      api.post('public/v1/messages/slack', {
        message: message
      })
        .then(response => {
          var data = response.data
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}
