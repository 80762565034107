import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import i18n from './i18n'
import store from '@/store/store'
import '@/assets/css/custom.css'
import VueMeta from 'vue-meta'
import VueMixpanel from 'vue-mixpanel'

import dateUtil from '@/js/utils/dateUtil'

import Toast from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";

//Tracking tools
import VueGtag from "vue-gtag";
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";
import slack from './js/services/slack'

Vue.config.productionTip = false

Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
})


Vue.use(VueGtag, {
  config: { id: process.env.VUE_APP_GTAG },
  appName: 'ASport',
  enabled: process.env.VUE_APP_GTAG,
  pageTrackerScreenviewEnabled: true
}, router);
Sentry.init({
  Vue,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ["localhost", "asport.lv", /^\//],
    }),
  ],
  tracesSampleRate: 1.0,
});

Vue.use(VueMixpanel, {
  token: process.env.VUE_APP_MIXPANEL_TOKEN ? process.env.VUE_APP_MIXPANEL_TOKEN : null,
  config: {
    track_pageview: "full-url",
    api_host: "https://api-eu.mixpanel.com",
    //debug: true
  }
})

Vue.use(Toast)


Vue.filter('formatDate', function (value) {
  if (value) {
    return dateUtil.formatDate(value)
  }
})
Vue.filter('formatDateTime', function (value) {
  if (value) {
    return dateUtil.formatDateTime(value)
  }
})
Vue.filter('formatShortDateTime', function (value) {
  if (value) {
    return dateUtil.formatShortDateTime(value)
  }
})

Vue.config.errorHandler = (err) => {
  console.error('Got error!', err);
  slack.sendMessage('Website errorHandler on page: ' + (window  && window.location ? window.location.href : 'unknown..') + ' with error: ' + err);
  throw err
};
window.onerror = function(message, source, lineno, colno, error) {
  console.error('Website error - window!', message, source, lineno, colno, error);
  if (!error){
    return false;
  }

  let details = "";
  if (message) {
    details += "Message: " + message + "; "
  }
  if (source) {
    details += "Source: " + source + "; "
  }
  if (lineno) {
    details += "LineNo: " + lineno + "; "
  }
  if (colno) {
    details += "ColNo: " + colno + "; "
  }
  if (error) {
    details += "Error: ["
    if (error.message) {
      details += "msg: " + error.message + "; "
    }
    if (error.stack) {
      details = " stack: " + error.stack + "; "
    }
    details += "]; "
  }

  //get device info
  details += "Device: ["
  if (window && window.navigator) {
    details += "UserAgent: " + window.navigator.userAgent + "; "
  }
  if (window && window.screen) {
    details += "Screen: " + window.screen.width + "x" + window.screen.height + "; "
  }
  details += "]; "

  slack.sendMessage('Website onError at page: ' + (window  && window.location ? window.location.href : 'unknown..') + ' with details: ' + details);
};
/*window.addEventListener('unhandledrejection', function(event) {
  console.log('Got error - event!', event, event.promise, event.reason)
  slack.sendMessage('Got error: ' + event.promise + " => " + event.reason);
  return
});*/

new Vue({
  vuetify,
  router,
  i18n,
  store,
  render: h => h(App)
}).$mount('#app')
