<template>
  <v-app class="as-page">
    <component :is="$route.meta.layout || 'div'">
      <router-view/>
    </component>

    <div class="main-wrapper">
      <cookie-law theme="blood-orange--rounded" :buttonText="$t('cookies.button')">
        <div slot="message">
          <div class="text-caption" v-html="$t('cookies.text')"/>
        </div>
      </cookie-law>
    </div>
    
  </v-app>
</template>

<script>
import CookieLaw from 'vue-cookie-law'
import locales from '@/locales/locales'

export default {
  components: { CookieLaw },

  metaInfo () {
    return {
      titleTemplate: this.$t('seo.title-template'),
      meta: [
        { 'http-equiv': 'Content-Type', content: 'text/html; charset=utf-8' },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },
        { name: 'url', content: window.location.href || '' },
        { property: 'og:url', content: window.location.href || '' },
        { property: 'og:type', content: 'website' },
        { property: 'og:site_name', content: 'ASport' },
        { property: 'og:locale', content: locales.getLocale().locale },
        { property: 'fb:app_id', content: '1174440243160965' },
      ],
      link: [
        { rel: 'canonical', href: this.canonicalUrl }
      ],
      htmlAttrs: {
        lang: this.$i18n.locale,
        class: this.webpSupported ? 'webp' : 'no-webp'
      }
    }
  },
  data () {
    return {
      webpSupported: false
    }
  },
  computed: {
    canonicalUrl: function () {
      let url = window.location.href;
      if (!url){
        return null
      }
      
      let indexOfQuestionMark = url.indexOf('?')
      if (indexOfQuestionMark > 0){
        url = url.substring(0, indexOfQuestionMark)
      }
      return url
    }
  },
  created: function(){
    this.webpSupportCheck()
  },
  methods:{
    webpSupportCheck () {
      (async () => {
        // If browser doesn't have createImageBitmap, we can't use webp.
        if (!self.createImageBitmap) {
          this.webpSupported = false
          return
        }

        // Base64 representation of a white point image
        const webpData = 'data:image/webp;base64,UklGRiQAAABXRUJQVlA4IBgAAAAwAQCdASoCAAEAAQAcJaQAA3AA/v3AgAA='
        // Retrieve the Image in Blob Format
        const blob = await fetch(webpData).then(r => r.blob())
        // If the createImageBitmap method succeeds, return true, otherwise false
        this.webpSupported = await createImageBitmap(blob).then(() => true, () => false)
      })()
    },
  }
}
</script>

