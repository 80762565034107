<template>
    <div class="as-main-layout">
      <v-app-bar
          app
          color="var(--color-pallet-5)"
          dark
          class="as-header mx-auto"
          height="64px"
        >
          <v-app-bar-nav-icon 
              class="d-block d-sm-none" 
              @click="drawer = true"
          >
          </v-app-bar-nav-icon>

          <v-spacer class="d-sm-none"></v-spacer>

          <v-toolbar-title>
              <router-link 
                  :to="getLocalisedUrl('/')">
              <img class="mr-1" src="@/assets/logo.png" height="30"/>
              <v-icon color="var(--color-pallet-1)">mdi-calendar-star</v-icon>
              </router-link>
          </v-toolbar-title>

          <v-spacer></v-spacer>


          <!-- Desktop menu -->
          <div class="d-none d-sm-block">
              <router-link
              v-for="item in menuItemsMain"
              :key="item.title"
              link
              :to="getLocalisedUrl(item.link)"
              class="mr-2"
              >
              {{ item.title }}
              </router-link>

              <v-menu
                  bottom
                  left
                  offset-y
                  eager
              >
              <template v-slot:activator="{ on, attrs }">
                  <v-btn
                  v-bind="attrs"
                  v-on="on"
                  height="20px"
                  plain
                  >
                  {{ languge }}
                  </v-btn>
              </template>


              <v-list class="text-right">
                  <v-list-item-group>
                  <v-list-item 
                      v-for="(item, index) in languages"
                      :key="index"
                      >
                      <v-btn
                      @click="changeLang(item.value)"
                      tile
                      plain
                      :class="item.value === languge ? 'as-active-language' : ''"
                      >
                      {{ item.text }}
                      </v-btn>
                  </v-list-item>
                  </v-list-item-group>
              </v-list>
              </v-menu>

              <div class="d-inline-block" v-if="!isLoggedIn">
              <router-link
                  v-for="item in menuItemsSecond"
                  :key="item.title"
                  link
                  :to="getLocalisedUrl(item.link)"
                  class="mr-2"
              >
                  {{ item.title }}
              </router-link>
              </div>
              
              <v-menu
                  v-else
                  bottom
                  left
                  offset-y
                  eager
              >
              <template v-slot:activator="{ on, attrs }">
                  <v-btn
                  dark
                  text
                  v-bind="attrs"
                  v-on="on"
                  >
                  <v-icon class="mr-2">mdi-account-circle</v-icon> {{ username }}
                  </v-btn>
              </template>

              <v-list class="text-right">
                  <v-list-item-group v-if="isLoggedIn && userOrganizations">
                  <v-list-item
                      link
                      :to="getLocalisedOrganizationUrl(userOrganizations)"
                  >
                      <v-list-item-content>
                      <v-list-item-title>{{ userOrganizations.name }}</v-list-item-title>
                      </v-list-item-content>
                  </v-list-item>
                  </v-list-item-group>
                  
                  <v-divider/>
                  
                  <profile-menu v-if="isLoggedIn"/>

                  <v-list-item-group v-if="isLoggedIn">
                  <v-divider/>
                  
                  <v-list-item
                      @click="logout"
                      class="logout"
                  >
                      <v-list-item-icon>
                      <v-icon>mdi-logout</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                      <v-list-item-title>{{ $t('common.button.logout') }}</v-list-item-title>
                      </v-list-item-content>
                  </v-list-item>
                  </v-list-item-group>
              </v-list>
              </v-menu>

          </div>


          <v-menu
              bottom
              left
              offset-y
              eager
          >
              <template v-slot:activator="{ on, attrs }">
              <v-btn
                  class="d-sm-none d-block"
                  dark
                  icon
                  v-bind="attrs"
                  v-on="on"
              >
                  <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
              </template>


              <v-list class="text-right d-sm-none d-block">
              <v-list-item-group>
                  <v-list-item>
                  <v-btn
                      v-for="(item, index) in languages"
                      :key="index"
                      @click="changeLang(item.value)"
                      tile
                      plain
                      :class="item.value === languge ? 'as-active-language' : ''"
                  >
                      {{ item.text }}
                  </v-btn>
                  </v-list-item>
              </v-list-item-group>
              
              <v-divider/>


              <v-list-item-group v-if="isLoggedIn && userOrganizations">
                  <v-list-item
                  link
                  :to="getLocalisedOrganizationUrl(userOrganizations)"
                  >
                  <v-list-item-content>
                      <v-list-item-title>{{ userOrganizations.name }}</v-list-item-title>
                  </v-list-item-content>
                  </v-list-item>
              </v-list-item-group>
              <v-list-item-group v-else>
                  <v-list-item
                  v-for="(myOrganization, index) in myOrganizations"
                  :key="index"
                  link
                  :to="getLocalisedOrganizationUrl(myOrganization)"
                  >
                  <v-list-item-content>
                      <v-list-item-title>{{ myOrganization.name }}</v-list-item-title>
                  </v-list-item-content>
                  </v-list-item>
              </v-list-item-group>
              
              <v-divider/>
              
              <profile-menu v-if="isLoggedIn"/>

              <v-list-item-group v-if="isLoggedIn">
                  
                  <v-divider/>
                  
                  <v-list-item
                  @click="logout"
                  class="logout"
                  >
                  <v-list-item-icon>
                      <v-icon>mdi-logout</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                      <v-list-item-title>{{ $t('common.button.logout') }}</v-list-item-title>
                  </v-list-item-content>
                  </v-list-item>
              </v-list-item-group>


              <v-list-item-group v-else>
                  <v-list-item
                  v-for="item in menuItemsSecond"
                  :key="item.title"
                  link
                  :to="getLocalisedUrl(item.link)"
                  >
                  <v-list-item-icon>
                      <v-icon>mdi-account-circle</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                      <v-list-item-title>{{ item.title }}</v-list-item-title>
                  </v-list-item-content>
                  </v-list-item>
              </v-list-item-group>
              </v-list>
          </v-menu>
          
      </v-app-bar>


      <!-- Mobile mainMenu -->
      <v-navigation-drawer
        v-model="drawer"
        fixed
        temporary
        eager
      >
      <v-list
          nav
          dense
      >
          <v-list-item-group>
          <v-list-item
              link
              :to="getLocalisedUrl('/')"
          >
              <v-list-item-content>
              <v-list-item-title>{{ $t('layout.menu.homepage') }}</v-list-item-title>
              </v-list-item-content>
          </v-list-item>
          </v-list-item-group>

          <v-divider/>

          <v-list-item-group>
          <v-list-item
              v-for="item in menuItemsMain"
              :key="item.title"
              link
              :to="getLocalisedUrl(item.link)"
          >
              <v-list-item-content>
              <v-list-item-title color="var(--color-pallet-2)">{{ item.title }}</v-list-item-title>
              </v-list-item-content>
          </v-list-item>
          </v-list-item-group>
      </v-list>
      </v-navigation-drawer>
      
      <v-main>
        <router-view :key="$route.fullPath" />
      </v-main>

      <v-footer
        padless
        >
        <v-card
            flat
            tile
            class="text-center"
            color="#eee"
            width="100%"
        >
            <!--<v-card-text class=" pt-0">
            Phasellus feugiat arcu sapien, et iaculis ipsum elementum sit amet. Mauris cursus commodo interdum. Praesent ut risus eget metus luctus accumsan id ultrices nunc. Sed at orci sed massa consectetur dignissim a sit amet dui. Duis commodo vitae velit et faucibus. Morbi vehicula lacinia malesuada. Nulla placerat augue vel ipsum ultrices, cursus iaculis dui sollicitudin. Vestibulum eu ipsum vel diam elementum tempor vel ut orci. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.
            </v-card-text>-->

            <v-divider></v-divider>

            <v-card-text>
              <div v-html="$t('layout.footer-text')"></div>
            </v-card-text>
        </v-card>
      </v-footer>
    </div>
</template>
<script>
import urlUtil from '@/js/utils/urlUtil'

import ProfileMenu from '@/components/private/layout/ProfileMenu'

export default {
  components: { ProfileMenu },
  name: 'MainLayout',
  data () {
    return {
      drawer: false,
      languages: [
        {'value': 'lv', 'text': 'LV'},
        {'value': 'ru', 'text': 'RU'}
      ],
      menuItemsSecond: [
        { 
          title: this.$t('layout.menu.login'),
          link: '/login',
        },
      ]
    }
  },
  computed: {
    languge: function() {
      return this.$i18n.locale
    },
    isLoggedIn: function() {
      return this.$store.getters['user/isLoggedIn']
    },
    username: function() {
      return this.$store.getters['user/name']
    },
    myOrganizations: function() {
      return this.$store.getters['session/myOrganizations']
    },
    userOrganizations: function() {
      return this.$store.getters['dashboard/myOrganization']
    },
    menuItemsMain: function() {
      return [
        /*{ 
          title: this.$t('layout.menu.about-us'),
          link: '/about-us' ,
          icon: 'mdi-home'
        }*/
      ]
    }
  },
  created: function(){
    if (this.isLoggedIn){
      this.$store.dispatch('user/refreshUserData')
    }
  },
  methods:{
    ...urlUtil,
    logout: function(){
      this.$store.dispatch('user/logout')
    },
    changeLang: function(lang) { 
      localStorage.setItem('lang', lang)
      const urlInDiffLanguage = urlUtil.getUrlInLocale(lang, urlUtil.getUrlWithoutLocale())
      window.open(urlInDiffLanguage, "_self")
    }
  }

};
</script>
<style>
.v-application .home-icon a {
  color: #eee;
  text-decoration: none;
  font-size: 22px;
}
.v-application.as-page .as-header a {
  color: #eee;
  text-decoration: none;
}
.v-application .lang-menu {
  cursor: pointer;
}
.v-application.as-page .as-header .as-organization-link {
  color: var(--color-pallet-1) !important;
  font-weight: 600;
  font-size: 24px
}
.v-application.as-page .as-header .as-organization-link .v-avatar {
  display: inline;
}
</style>