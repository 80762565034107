import api from '@/js/services/axios'
import urlUtil from '@/js/utils/urlUtil'

const CDN_URL = process.env.VUE_APP_CDN_URL

export default {
  LAYOUT_STYLE_CARD: "CARD",
  LAYOUT_STYLE_WEEK: "WEEK",

  findByIdentifier (identifier) {
    var queryMap = new Map()
    queryMap.set('identifier', identifier)
    var query = urlUtil.prepareQuery(queryMap)

    return new Promise((resolve, reject) => {
      api.get('public/v1/organization/find' + query)
        .then(response => {
          var data = response.data
          resolve(this._processOrganization(data))
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  _processOrganization(organization){
    if (!organization){
      return null
    }

    organization.color_text = null;
    if (organization.frontend_settings){
      try {
        let frontendSettings = JSON.parse(organization.frontend_settings);
        organization.color_text = frontendSettings.color_text;
      } catch (error) {
        throw new Error('Failed to parse frontend settings JSON:', error);
      }
    }
    if (!organization.color_text){
      organization.color_text = organization.color;
    }
    if (!organization.color_text){
      organization.color_text = "#000000";
    }

    let identifier = organization.identifier
    if (identifier){
      let coverImageSrc = CDN_URL + '/organization/' + identifier + '/' + identifier
      
      if (identifier === 'veselibas-sporta-stacija' && !coverImageSrc.endsWith('.webp')){
        coverImageSrc = coverImageSrc + '_1920.webp'
      }
      
      if (!coverImageSrc.endsWith('.jpg') && !coverImageSrc.endsWith('.webp')){
        coverImageSrc = ""
      }


      organization.src_image_1920_webp = coverImageSrc
      organization.src_image_600_webp = organization.src_image_1920_webp.replace('_1920.', '_600.')
      organization.src_image_1200_webp = organization.src_image_1920_webp.replace('_1920.', '_1200.')
      organization.src_image_600_jpg = organization.src_image_600_webp.replace('.webp', '.jpg')
      organization.src_image_1200_jpg = organization.src_image_1200_webp.replace('.webp', '.jpg')
      organization.src_image_1920_jpg = organization.src_image_1920_webp.replace('.webp', '.jpg')
      
      if (CDN_URL){
        organization.src_logo_small = CDN_URL + '/organization/' + identifier + '/logo_40.jpg'
        organization.src_logo_large = CDN_URL + '/organization/' + identifier + '/logo_144.jpg'  
      } else {
        organization.src_logo_small = '/img/organization/' + identifier + '/logo_40.jpg'
        organization.src_logo_large = '/img/organization/' + identifier + '/logo_144.jpg'
      }
    }

    return organization
  }
}
