
export default {

  delay(milliseconds){
    return new Promise(resolve => {
      setTimeout(resolve, milliseconds);
    });
  },
  
  clone (object) {
    if (!object) {
      return null
    }

    return JSON.parse(JSON.stringify(object))
  },
  
  findFirstNonNullArgument(...args) {
    return args.find(arg => arg != null);
  },
  
  strip (text, maxLength) {
    if (!text) {
      return ''
    }

    if (text.length > maxLength) {
      if (maxLength <= 3) {
        text.substr(0, maxLength)
      } else {
        text = text.substr(0, maxLength - 2) + '..'
      }
    }
    return text
  },

  removeSpaces(text) {
    if (!text) {
      return text
    }
    return text.replaceAll(' ', '')
  },

  removeHtml(text) {
    if (!text) {
      return text
    }

    return text.replace(/<[^>]*>/g, '');
  },

  removeArrayElement(array, element) {
    if (!array || !element) {
      return
    }

    const index = array.indexOf(element)
    if (index > -1) {
      array.splice(index, 1)
    }
  },
}