import axios from 'axios'
import i18n from '@/i18n'
import store from '@/store/store'

const api = axios.create({
  baseURL: process.env.VUE_APP_API_URL
})

api.interceptors.request.use(
  config => {

    config.headers.language = i18n.locale
    const token = store.getters['user/token']
    if (token) {
      config.headers.Authorization = 'Bearer ' + token
    }

    var url = config.url.toLowerCase()
    if (url.startsWith('/')) {
      var baseUrl = config.baseURL
      baseUrl = baseUrl.slice(0, -8)
      config.baseURL = baseUrl
    }

    return config
  },

  error => Promise.reject(error)
)

api.interceptors.response.use(
  (response) => {
    return response
  },
  
  (error) => {
    const resp = error.response
    if (resp){
      if (resp.status === 401) {
        let token = store.getters['user/token']
        if (error.config && !error.config.__isRetryRequest && token){
          store.dispatch('user/logout')
          location.reload();
        } else {
          //throw error
        }
      }
    }


    return Promise.reject(error)
  })

export default api
