<template>
  <div v-if="organization" :style="'--organization-color: ' + organizationColor + ';--organization-text-color:' + organization.color_text + ';'">
    <div v-if="isLoadingOrganization || !organization">
      <v-container>
        <v-row v-if="isLoadingOrganization">
          <v-col cols="12">
            <loading-in-progress/>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <div v-else>
      <v-app-bar
        app
        color="#eee"
        dark
        class="as-header as-organization-page"
        dense
        absolute
        height="44px"
      >
        <v-toolbar-title>
          <router-link 
            link
            :to="organizationUrl"
          >
            <v-avatar size="35px" class="mr-2">
                <v-img :src='organization.src_logo_small' :alt="organization.name"></v-img>
            </v-avatar>
            {{ organization.name }}
          </router-link>
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <div v-if="organization && organization.personal_url">
          <div v-if="organization.personal_url.includes('www.instagram.com')">
            <a :href="organization.personal_url" target="_blank">
              <v-icon class="as-organization-style">mdi-instagram</v-icon>
            </a>
          </div>
          <a v-else :href="organization.personal_url" target="_blank">{{ $t("organization.header.about-us") }}</a>
        </div>
        
      </v-app-bar>

      <v-main>
        <router-view :key="$route.fullPath"/>
      </v-main>
      
      <AppFooter/>
    </div>
  </div>
</template>

<script>
import AppFooter from '@/components/public/layout/AppFooter.vue'
import LoadingInProgress from '@/components/public/LoadingInProgress.vue'

import OrganizationRepository from '@/js/api/OrganizationRepository'
import urlUtil from '@/js/utils/urlUtil'

export default {
  components: { AppFooter, LoadingInProgress },
  
  metaInfo () {
    return {
      title: this.organization ? this.organization.name : "ASport",
      titleTemplate: this.$t('seo.organization-title-template', { organization: this.organization ? this.organization.name : "" })
    }
  },
  data: () => ({
    isLoadingOrganization: true
  }),
  created: async function(){
    let orgIdentifier = this.$route.params.identifier
    if (this.organization && this.organization.identifier === orgIdentifier){
      await this.$store.dispatch('organization/resetState')
    }

    await OrganizationRepository.findByIdentifier(orgIdentifier)
    .then((organization) => {
      this.$store.dispatch('organization/setOrganization', organization)
      if (!organization){
        this.$router.push(window.location.pathname + "/404")
        return
      }
      this.isLoadingOrganization = false
    })
    .catch (error => {
      console.log('Error:', error)
      this.isLoadingOrganization = false
      throw error
    })
  },
  computed: {
    organization: function() {
      return this.$store.getters['organization/organization']
    },
    organizationUrl: function() {
      if (this.organization){
        return urlUtil.getLocalisedOrganizationUrl(this.organization)
      }
      return null
    },
    myClass: function() {
      return this.$store.getters['organization/myClass']
    },
    classUrl: function() {
      if (this.organization && this.myClass){
        return urlUtil.getLocalisedOrganizationUrl(this.organization, 'class/' + this.myClass.identifier)
      }
      return null
    },
    isMainPage: function() {
      if (this.organization){
        return urlUtil.getLocalisedOrganizationUrl(this.organization, null) === this.$route.fullPath
      }
      return false
    },
    organizationColor () {
      if (!this.organization)
        return ""
      
      if (this.organization && this.organization.color && this.organization.color.match(/^#[0-9A-F]{6}$/i)){
          return this.organization.color
      }
      return '#ffa500'
    }
  }
}
/**var(--color-pallet-1) !important; */
</script>
<style lang="scss">
.v-application.as-page .as-organization-page .home-icon a {
  text-decoration: none;
  font-size: 16px;
}
.v-application.as-page .as-organization-page.as-header a {
  text-decoration: none;
}
.v-application.as-page .as-organization-page.as-header a {
  color: var(--organization-text-color) !important;
  font-weight: 450;
  font-size: 16px
}
.v-application.as-page .as-organization-style {
  color: var(--organization-text-color) !important;
}
</style>
