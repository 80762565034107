<template>
  <v-list-item-group>
    <v-list-item
      v-for="item in items"
      :key="item.title"
      link
      :to="getLocalisedUrl(item.link)"
    >
      <v-list-item-icon>
        <v-badge
          :content="item.showBadge"
          :value="item.showBadge"
          color="var(--color-pallet-4)"
          overlap
        >
          <v-icon>{{ item.icon }}</v-icon>
        </v-badge>
      </v-list-item-icon>

      <v-list-item-content>
        <v-list-item-title>{{ item.title }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

  </v-list-item-group>
</template>

<script>
import urlUtil from '@/js/utils/urlUtil'

export default {
  data () {
    return {
      theme: 'light'
    }
  },
  computed: {
    items: function() {
      return [
        /*{ 
          title: this.$t('dashboard.navigation.main'), 
          icon: 'mdi-home-analytics', 
          mdi-image-outline
          link: '/mypages/home' 
        },*/
        //{ title: 'Account', icon: 'mdi-account-box', link: '/mypages/account' },
        { 
          title: this.$t('dashboard.navigation.organization'), 
          icon: 'mdi-newspaper-variant-outline', 
          link: '/mypages/organization',
          showBadge: null 
        },
        { 
          title: this.$t('dashboard.navigation.classes'), 
          icon: 'mdi-view-grid-outline', 
          link: '/mypages/classes',
          showBadge: null
        },
        { 
          title: this.$t('dashboard.navigation.events'), 
          icon: 'mdi-calendar-star', 
          link: '/mypages/events',
          showBadge: null
        },
        { 
          title: this.$t('dashboard.navigation.manual'), 
          icon: 'mdi-chat-question-outline', 
          link: '/mypages/manual',
          showBadge: this.showNewsBadge
        }
      ]
    },
    showNewsBadge: function() {
      return this.$store.getters['myStorage/showNewsNotification'] ? "!": null;
    }
  },
  methods: {
    ...urlUtil
  }
}
</script>