import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/store'

import i18n from '@/i18n'
import locales, { SUPPORTED_LOCALES } from '@/locales/locales.js'
import urlUtil from '@/js/utils/urlUtil'

import routes from './routes'
import commonUtil from '@/js/utils/commonUtil'
import OrganizationRepository from '@/js/api/OrganizationRepository'

Vue.use(VueRouter)

function getLocaleRegex () {
  let reg = ''
  SUPPORTED_LOCALES.forEach((locale, index) => {
    reg = `${reg}${locale.code}${index !== SUPPORTED_LOCALES.length - 1 ? '|' : ''}`
  })
  return `(${reg})`
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [{
    path: `/:locale${getLocaleRegex()}?`,
    component: {
      template: '<router-view/>'
    },
    children: routes
  }],
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
})

router.beforeEach(async (to, from, next) => {
  // If user visits us with new session then set web language based on previous session (stored in local storage) or for first time visits based on browser language priority
  if (!from.name) { // If this is first visit to page (from.name will be null)
    store.dispatch('session/setLandingUrl', window.location.href)
    store.dispatch('session/setReferrerUrl', document.referrer)

    //Setup locale
    let useLanguage = commonUtil.findFirstNonNullArgument(urlUtil.getLangFromUrl(), localStorage.getItem('lang'), 'lv')
    if (!locales.isLanguageSupported(useLanguage)){
      useLanguage = 'lv'
    }

    let organizationIdenfier = urlUtil.getOrganizationIdentifier(to.path)
    if (organizationIdenfier && useLanguage !== 'lv'){
      useLanguage = 'lv'
    }

    let localeFromUrl = locales.getLocaleByCode(to.params.locale)
    if (useLanguage !== localeFromUrl.code
        || useLanguage !== localStorage.getItem('lang')
        || useLanguage !== i18n.locale){
      localStorage.setItem('lang', useLanguage)
      i18n.locale = useLanguage
      router.push(urlUtil.getUrlInLocale(useLanguage, urlUtil.getUrlWithoutLocale()))
      return
    }
  }

  let organizationIdenfier = urlUtil.getOrganizationIdentifier(to.path)
  if (organizationIdenfier){
    let organizationByLocation = store.getters['session/organizationByLocation']
    if (!organizationByLocation || organizationIdenfier !== organizationByLocation.identifier){
      organizationByLocation = await OrganizationRepository.findByIdentifier(organizationIdenfier)
      if (!organizationByLocation){
        next({ path: "/" })
        return
      }
      store.dispatch('session/setOrganizationByLocation', organizationByLocation)
    }
  } else {
    if (store.getters['session/organizationByLocation'])
      store.dispatch('session/setOrganizationByLocation', null)
  }

  let isLoggedIn = store.getters['user/isLoggedIn']
  if (isLoggedIn && to.fullPath.startsWith(urlUtil.getLocalisedUrl('/login'))){
    next({ path: urlUtil.getLocalisedUrl('/mypages') })
    return
  }
  if (!isLoggedIn && to.matched.some(record => record.meta.requiresAuth)) {
    next({ path: urlUtil.getLocalisedUrl('/login'), query: { returnUrl: to.path } })
    return
  }
  
  next()
})

export default router
