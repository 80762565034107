import api from '@/js/services/axios'
import OrganizationRepository from '../OrganizationRepository'


export default {
  LAYOUT_STYLE_CARD: OrganizationRepository.LAYOUT_STYLE_CARD,
  LAYOUT_STYLE_WEEK: OrganizationRepository.LAYOUT_STYLE_WEEK,

  get (organizationId) {
    return new Promise((resolve, reject) => {
      api.get('private/v1/organization/' + organizationId)
        .then(response => {
          var data = response.data
          resolve(this._processOrganization(data))
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  
  updateOrganization (myOrganization){
    return new Promise((resolve, reject) => {
      api.patch('private/v1/organization/' + myOrganization.id, 
        {
          name: myOrganization.name,
          description: myOrganization.description,
          layout_style: myOrganization.layout_style
        })
        .then(response => {
          var data = response.data
          resolve(this._processOrganization(data))
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  _processOrganization(organization){
    if (!organization){
      return null
    }

    organization = OrganizationRepository._processOrganization(organization)
    return organization
  }
}
