import HomeView from '../views/HomeView.vue'
import MainLayout from '../layouts/MainLayout.vue'
import PortalLayout from '../layouts/PortalLayout.vue'
import OrganizationLayout from '../layouts/OrganizationLayout.vue'


export default [
  {
    path: '',
    name: 'home',
    component: HomeView,
    meta: {
      layout: MainLayout
    }
  },
  {
    path: 'about-us',
    name: 'about-us',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
    meta: {
      layout: MainLayout
    }
  },
  {
    path: 'terms-and-conditions',
    name: 'termsAndConditions',
    component: () => import(/* webpackChunkName: "main" */ '../views/TermsAndConditions.vue'),
    meta: {
      layout: MainLayout
    }
  },
  {
    path: 'login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/LoginView.vue'),
    meta: {
      layout: MainLayout
    }
  },
  {
    path: 'join/:identifier',
    component: () => import(/* webpackChunkName: "organization-pages" */ '@/layouts/OrganizationLayout.vue'),
    meta: {
      layout: OrganizationLayout
    },
    children: [
      {
        path: '',
        name: 'OrganizationView',
        component: () => import(/* webpackChunkName: "organization-pages" */ '@/views/organization/OrganizationView.vue')
      },
      {
        path: 'class/:class',
        name: 'ClassView',
        component: () => import(/* webpackChunkName: "organization-pages" */ '@/views/organization/ClassView.vue'),
        
      },
      {
        path: 'registration-completed',
        name: 'join-completed',
        component: () => import(/* webpackChunkName: "organization-pages" */ '@/views/organization/JoinEventThankYouView.vue')
      },
      {
        path: 'cancel-event/:eventUuid/:participantUuid',
        name: 'cancel-event',
        component: () => import(/* webpackChunkName: "organization-pages" */ '@/views/organization/CancelEventView.vue')
      },
      {
        path: 'cancel-event-completed',
        name: 'cancel-event-completed',
        component: () => import(/* webpackChunkName: "organization-pages" */ '@/views/organization/CancelEventCompletedView.vue')
      },
    ]
  },
  {
    path: 'mypages',
    component: () => import(/* webpackChunkName: "portal-pages" */ '@/layouts/PortalLayout.vue'),
    meta: {
      layout: PortalLayout,
      requiresAuth: true
    },
    children: [
      {
        path: '',
        name: 'Events',
        component: () => import(/* webpackChunkName: "my-pages" */ '../views/myPages/MyEventsView.vue')
      },
      {
        path: 'manual',
        name: 'AppManual',
        component: () => import(/* webpackChunkName: "my-pages" */ '../views/myPages/AppManual.vue')
      },
      {
        path: 'classes',
        name: 'MyClasses',
        component: () => import(/* webpackChunkName: "my-pages" */ '../views/myPages/MyClassesView.vue'),
      },
      {
        path: 'events',
        name: 'MyEvents',
        component: () => import(/* webpackChunkName: "my-pages" */ '../views/myPages/MyEventsView.vue'),
      },
      {
        path: 'organization',
        name: 'MyOrganization',
        component: () => import(/* webpackChunkName: "my-pages" */ '../views/myPages/MyOrganizationView.vue'),
      }
    ]
  },
  {
    path: '*',
    name: 'PageNotFound',
    component: () => import(/* webpackChunkName: "error" */ '../views/ErrorView.vue'),
  }
]