import api from '@/js/services/axios'

export default {
  getMe () {
    return new Promise((resolve, reject) => {
      api.get('private/v1/users/me')
        .then(response => {
          var data = response.data
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}
