import commonUtil from "@/js/utils/commonUtil"

const getDefaultState = () => {
  return {
    eventSignup: null,
    eventCancel: null,
    organizationByLocation: null,
    myOrganizations: [],
    landingUrl: null,
    referrerUrl: null
  }
}

export default {
  namespaced: true,
  state: getDefaultState(),

  mutations: {
    resetState (state) {
      Object.assign(state, getDefaultState())
    },
    addEventSignup (state, payload) {
      let signed = {
        "myClass": payload.myClass,
        "myEvent": payload.myEvent,
        "myParticipant": payload.myParticipant
      }
      state.eventSignup = signed
    },
    addEventCancel (state, payload) {
      let cancelData = {
        "myClass": payload.myClass,
        "myEvent": payload.myEvent,
      }
      state.eventCancel = cancelData
    },
    setOrganizationByLocation (state, organization) {
      if (!organization){
        state.organizationByLocation = null  

      } else {
        let basicOrganizationData = {
          id: organization.id,
          name: organization.name,
          identifier: organization.identifier
        }
        
        state.organizationByLocation = basicOrganizationData
      }
    },
    addOrganizationToMyList(state, organization) {
      if (!organization
          || !organization.id
          || !organization.identifier
          || !organization.name) {
        return
      }
      
      let hasExactOrganization = state.myOrganizations.some(item => item.id === organization.id 
        && item.name === organization.name 
        && item.identifier === organization.identifier)
      if (hasExactOrganization){
        return
      }

      let basicOrganizationData = {
        id: organization.id,
        name: organization.name,
        identifier: organization.identifier
      }

      const item = state.myOrganizations.find(item => item.id === basicOrganizationData.id)
      if (item){
        basicOrganizationData = commonUtil.clone(basicOrganizationData)
        Object.assign(item, basicOrganizationData)
      } else {
        state.myOrganizations.push(basicOrganizationData)
      }
      
      let maxOrganizationLimit = 1
      let size = state.myOrganizations.length
      if (size > maxOrganizationLimit){
        state.myOrganizations.splice(0, size - maxOrganizationLimit)
      }
    },
    clearMyOrganizationList(state) {
     state.myOrganizations = [] 
    },
    setLandingUrl(state, landingUrl) {
      state.landingUrl = landingUrl
     },
    setReferrerUrl(state, referrerUrl) {
      state.referrerUrl = referrerUrl
    }
  },

  actions: {
    resetState ({ commit }) {
      commit('resetState')
    },

    eventSignUp ({ commit }, eventData) {
      commit('addEventSignup', eventData)
    },

    eventCancel({ commit }, eventData) {
      commit('addEventCancel', eventData)
    },
    
    setOrganizationByLocation({ commit }, organization) {
      commit('setOrganizationByLocation', organization)
    },
    
    addMyOrganization({ commit }, organization) {
      commit('addOrganizationToMyList', organization)
    },
    clearMyOrganization({ commit }) {
      commit('clearMyOrganizationList')
    },
    setLandingUrl({ commit }, landingUrl) {
      commit('setLandingUrl', landingUrl)
    },
    setReferrerUrl({ commit }, referrerUrl) {
      commit('setReferrerUrl', referrerUrl)
    },
  },
  getters: {
    eventSignup: state => state.eventSignup,
    eventCancel: state => state.eventCancel,
    organizationByLocation: state => state.organizationByLocation,
    myOrganizations: state => state.myOrganizations,
    landingUrl: state => state.landingUrl,
    referrerUrl: state => state.referrerUrl,
  }
}