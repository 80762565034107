import i18n from '@/i18n'

export const SUPPORTED_LOCALES = [{
  code: 'lv',
  base: '',
  locale: 'lv_LV'
}, /* English is not active{
  code: 'en',
  base: '/en',
  locale: 'en_US'
}, */{
  code: 'ru',
  base: '/ru',
  locale: 'ru_RU'
}]

export const LOCALES_REGEX = '(lv|ru)'//'(lv|en|ru)'

export default {
  getLocaleByCode (localeCode) {
    if (!localeCode) {
      return this.getLocaleByCode('lv')
    }

    return SUPPORTED_LOCALES.find(loc => loc.code === localeCode)
  },

  getLocale () {
    return this.getLocaleByCode(i18n.locale)
  },

  isLanguageSupported(lang){
    if (!lang){
      return false
    }
    return lang.match(LOCALES_REGEX)
  }
}
