const getDefaultState = () => {
  return {
    organization: null
  }
}

export default {
  namespaced: true,
  state: getDefaultState(),

  mutations: {
    resetState (state) {
      Object.assign(state, getDefaultState())
    },
    setOrganization (state, organization) {
      if (!organization){
        state.organization = null  

      } else {
        /*let basicOrganizationData = {
          id: organization.id,
          name: organization.name,
          identifier: organization.identifier,
          description: organization.description
        }
        
        state.organization = basicOrganizationData*/
        state.organization = organization
      }
    },
    setClass (state, myClass) {
      if (!myClass){
        state.myClass = null  

      } else {
        state.myClass = myClass
      }
    }
  },

  actions: {
    resetState ({ commit }) {
      commit('resetState')
    },

    setOrganization({ commit }, organization) {
      commit('setOrganization', organization)
    },
    setClass({ commit }, myClass) {
      commit('setClass', myClass)
    }
  },
  getters: {
    organization: state => state.organization,
    myClass: state => state.myClass
  }
}