import Vue from 'vue'
import Vuex from 'vuex'
import sessionModule from './modules/session'
import userModule from './modules/user'
import dashboardModule from './modules/dashboard'
import organizationModule from './modules/organization'
import myStorageModule from './modules/myStorage'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    user: userModule,
    dashboard: dashboardModule,
    session: sessionModule,
    organization: organizationModule,
    myStorage: myStorageModule
  },
  plugins: [
    createPersistedState({
      storage: window.localStorage,
      paths: ['user', 'dashboard', 'myStorage'],
      key: 'my-event-store'
    }),
    createPersistedState({
      storage: window.sessionStorage,
      paths: ['session', 'organization'],
      key: 'my-event-session'
    })
  ]
})
