<template>
  <div class="as-website">

    <v-container fluid class="py-14">
        <v-row justify="center" align="center">
          <v-col cols="12" sm="6" md="5" class="text-center">
            <img class="hero-img" src="/img/devices_2.png" alt="laptopView"/>
          </v-col>
          <v-col cols="12" sm="6" md="5" lg="4" class="text-center">
            <h1 class=""><span class="text-color-main">{{ $t('pages.home-view.title') }}</span> <span class="text-color-pallet-2">{{ $t('pages.home-view.title-2') }}</span></h1>
            <div class="text-caption font-italic pt-2">{{ $t('pages.home-view.sub-title') }}</div>
          </v-col>
        </v-row>
    </v-container>

    <v-container fluid class="py-14 as-section-grey gradient-dark-bottom">
      <v-row justify="center" align="center">
        <v-col cols="12" sm="8" class="text-center">
          <img src="@/assets/logo.png" class="logo" alt="ASport"/>
          <h3 class="text-color-grey text-h4 font-weight-bold">{{ $t('pages.home-view.sections.asport-content') }}</h3>
        </v-col>
      </v-row>
    </v-container>

    <v-container class="py-14">
      <v-row justify="center" align="center">
        <v-col cols="12" sm="2" md="4" class="text-center d-none d-md-block">
          <img src="/img/mobile_3.png" class="mobile-img" alt="devicesView"/>
        </v-col>
        <v-col cols="12" sm="8" md="8" class="text-center py-0 order-sm-first">
          <h2 v-html="$t('pages.home-view.sections.mission-content')"></h2>
          <v-list three-line class="as-list">
            <v-list-item three-line>
              <v-list-item-icon>
                <v-icon large color="var(--color-pallet-2)">mdi-meditation</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="font-weight-bold text-color-pallet-2">{{ $t('pages.home-view.list.item1-title') }}</v-list-item-title>
                <v-list-item-subtitle class="as-subtitle">{{ $t('pages.home-view.list.item1-content') }}</v-list-item-subtitle>
                <v-list-item-subtitle class="font-weight-bold">{{ $t('pages.home-view.list.item1-highlight') }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-icon>
                <v-icon large color="var(--color-pallet-2)">mdi-clock-fast</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="font-weight-bold text-color-pallet-2">{{ $t('pages.home-view.list.item2-title') }}</v-list-item-title>
                <v-list-item-subtitle class="as-subtitle">{{ $t('pages.home-view.list.item2-content') }}</v-list-item-subtitle>
                <v-list-item-subtitle class="font-weight-bold">{{ $t('pages.home-view.list.item2-highlight') }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-icon>
                <v-icon large color="var(--color-pallet-2)">mdi-speedometer</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="font-weight-bold text-color-pallet-2">{{ $t('pages.home-view.list.item3-title') }}</v-list-item-title>
                <v-list-item-subtitle class="as-subtitle">{{ $t('pages.home-view.list.item3-content') }}</v-list-item-subtitle>
                <v-list-item-subtitle class="font-weight-bold">{{ $t('pages.home-view.list.item3-highlight') }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
        <v-col cols="12" md="6" class="text-center pt-5">
          <v-btn @click="showSignupDialog('Uzzināt vairāk')" width="250" color="var(--color-pallet-2)" dark>{{ $t('pages.home-view.sections.mission-button') }} </v-btn>
        </v-col>
      </v-row>
    </v-container>
    
    <v-container fluid class="py-10 as-section-grey gradient-dark-bottom">
      <v-row justify="center">
        <v-col cols="12" class="text-center">
          <h2 class="text-color-pallet-2" v-html="$t('pages.home-view.sections.price-title')"/>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="12" sm="8" lg="5" class="text-center">
          <p v-html="$t('pages.home-view.sections.price-content')"/>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="12" class="text-center">
          <v-btn @click="showSignupDialog('Uzzināt izmaksas')" width="250" color="var(--color-pallet-2)" dark>{{ $t('pages.home-view.sections.price-button') }}</v-btn>
        </v-col>
      </v-row>
    </v-container>

    <v-container fluid class="py-10">
      <v-row justify="center">
        <v-col cols="12" md="6" class="text-center">
          <h2 class="text-color-pallet-2" v-html="$t('pages.home-view.sections.clients-title')"></h2>
          <p class="mt-2" v-html="$t('pages.home-view.sections.clients-content')"></p>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="12" sm="5" md="3" lg="3" class="text-center mb-2 vingro-ar-signi">
          <router-link
              target="_blank"
              :to="getLocalisedUrl('/join/vingro-ar-signi?as_source=asport')"
              @click.native="trackClick('vingro-ar-signi')">
            <v-avatar size="144px" class="mb-3">
              <img :src="getImageUrl('vingro-ar-signi')" alt="Vingro ar Signi"/>
            </v-avatar>
            <br/>
            <div class="font-weight-bold">Vingro ar Signi</div>
          </router-link>
        </v-col>
        <v-col cols="12" sm="5" md="3" lg="3" class="text-center mb-2 veselibas-sporta-stacija">
          <router-link 
              target="_blank"
              :to="getLocalisedUrl('/join/veselibas-sporta-stacija?as_source=asport')"
              @click.native="trackClick('veselibas-sporta-stacija')">
            <v-avatar size="144px" class="mb-3">
              <img :src="getImageUrl('veselibas-sporta-stacija')" alt="Veselibas Sporta Stacija"/>
            </v-avatar>
            <br/>
            <div class="font-weight-bold">Veselibas Sporta Stacija</div>
          </router-link>
        </v-col>
        <v-col cols="12" sm="5" md="3" lg="3" class="text-center mb-2 mindfulstudioriga">
          <router-link 
              target="_blank"
              :to="getLocalisedUrl('/join/mindfulstudioriga?as_source=asport')"
              @click.native="trackClick('mindfulstudioriga')">
            <v-avatar size="144px" class="mb-3">
              <img :src="getImageUrl('mindfulstudioriga')" alt="MindfulStudio"/>
            </v-avatar>
            <br/>
            <div class="font-weight-bold">MINDFUL studio</div>
          </router-link>
        </v-col>
      </v-row>
    </v-container>


    <v-container fluid class="py-10 as-section-grey gradient-dark-bottom">
      <v-row justify="center">
        <v-col cols="12" class="text-center">
          <h2 class="text-color-pallet-2" v-html="$t('pages.home-view.sections.where-title')"/>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="12" sm="8" lg="5" class="text-center">
          <p v-html="$t('pages.home-view.sections.where-content')"/>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="12" class="text-center">
          <v-btn @click="showSignupDialog('Vēlos pieteikties')" width="250" color="var(--color-pallet-2)" dark>{{ $t('pages.home-view.sections.where-button') }}</v-btn>
        </v-col>
      </v-row>
    </v-container>

    <v-container fluid class="py-10 as-homepage-main-section">
      <v-row justify="center">
        <v-col cols="12" class="text-center">
          
        </v-col>
      </v-row>
    </v-container>

    <v-dialog v-model="signUpDialog" max-width="500">
      <v-card>
        <v-card-title>
          <span v-if="dialogType === 'features'" class="text-h4" v-html="$t('pages.home-view.contact-form.feature-title')"></span>
          <span v-else-if="dialogType === 'price'" class="text-h4" v-html="$t('pages.home-view.contact-form.price-title')"></span>
          <span v-else class="text-h4" v-html="$t('pages.home-view.contact-form.title')"></span>
        </v-card-title>
        <v-card-subtitle class="pt-2">
          <span v-if="dialogType === 'features'" v-html="$t('pages.home-view.contact-form.feature-content')"></span>
          <span v-else-if="dialogType === 'price'" v-html="$t('pages.home-view.contact-form.price-content')"></span>
          <span v-else v-html="$t('pages.home-view.contact-form.content')"></span>
        </v-card-subtitle>
        <v-card-text>
          <v-form ref="form" v-model="valid">
            <v-tabs v-model="tabs" background-color="transparent" centered>
              <v-tab key="individual">
                {{ $t('pages.home-view.contact-form.trainer') }}
              </v-tab>
              <v-tab key="studio">
                {{ $t('pages.home-view.contact-form.studio') }}
              </v-tab>

              <v-tab-item>
                <v-text-field 
                  :label="$t('pages.home-view.contact-form.form.trainer-name')"
                  :rules="[rules.required]" 
                  v-model="contactName"></v-text-field>
              </v-tab-item>
              <v-tab-item>
                <v-text-field 
                  :label="$t('pages.home-view.contact-form.form.studio-name')"
                  :rules="[rules.required]" 
                  v-model="contactName"></v-text-field>
              </v-tab-item>
            </v-tabs>
            <v-text-field 
              :label="$t('pages.home-view.contact-form.form.phone')"
              :rules="[rules.required, rules.phone]" 
              v-model="contactPhone"></v-text-field>
            <v-text-field 
              :label="$t('pages.home-view.contact-form.form.email')"
              :rules="[rules.required, rules.email]" 
              v-model="contactEmail"></v-text-field>
            <v-textarea 
              :label="$t('pages.home-view.contact-form.form.message')"
              rows="2"
              auto-grow
              v-model="contactDescription"></v-textarea>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn color="dark red" text @click="signUpDialog = false">{{ $t('pages.home-view.contact-form.form.button-close') }}</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" :disabled="loading" text @click="sendContactForm">{{ $t('pages.home-view.contact-form.form.button-send') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    
  </div>
</template>

<script>
import slack from '@/js/services/slack'
import urlUtil from '@/js/utils/urlUtil'
import validationRules from '@/js/utils/validation-rules'

export default {
  name: 'HomeView',
  data: () => ({
    contactDetail: null,
    signUpDialog: false,
    dialogType: null,
    contactName: null,
    contactPhone: null,
    contactEmail: null,
    contactDescription: null,
    contactButton: null,
    rules: validationRules,
    valid: true,
    tabs: null,
    loading: false,
  }),
  metaInfo () {
    return {
      title: this.$t('pages.home-view.seo-title') || '' ,
      meta: [
        { name: 'title', content: this.$t('pages.home-view.seo-title') || '' },
        { name: 'description', content: this.$t('pages.home-view.seo-description') || '' },
        { name: 'url', content: window.location.href || '' },
        { property: 'og:title', content: this.$t('pages.home-view.seo-title') || '' },
        { property: 'og:description', content: this.$t('pages.home-view.seo-description') || '' },
      ]
    }
  },
  computed: {
    landingUrl: function() {
      return this.$store.getters['session/landingUrl']
    },
    referrerUrl: function() {
      return this.$store.getters['session/referrerUrl']
    }
  },
  created: function() {
    this.$mixpanel.track('ViewHomePage', {
        landingUrl: this.landingUrl,
        referrerUrl: this.referrerUrl
    });
  },
  methods: {
    ...urlUtil,
    sendContactForm: async function() {
      this.loading = true
      const inputForm = this.$refs.form
      const isValid = await inputForm.validate();
      this.error = null
      if (!isValid) {
        this.loading = false
        return
      }

      this.$mixpanel.identify(this.contactEmail);
      this.$mixpanel.track('HomePageSubmitForm', {
        distinct_id: this.contactEmail,
        button: this.contactButton,
        landingUrl: this.landingUrl,
        referrerUrl: this.referrerUrl
      });

      slack.sendMessage("Contact form to join ASport => Name:" + this.contactName + " Phone:" + this.contactPhone + " Email:" + this.contactEmail + " Details:" + this.contactDescription + " Button:" + this.contactButton)
      .then(() => {
        this.loading = false
        this.contactName = null
        this.contactPhone = null
        this.contactEmail = null
        this.contactDescription = null
        this.contactButton = null
        this.$toast.success(this.$t('pages.home-view.contact-form.success-message'))
        this.signUpDialog = false
        this.$refs.form.reset()
      })
      .catch((error) => {
        this.loading = false
        console.error(error)
        this.$toast.error(this.$t('pages.home-view.contact-form.submit-error'))
      })
    },
    showSignupDialog: function(actionText) {
      this.signUpDialog = true
      this.contactButton = actionText
      this.$mixpanel.track('HomePageClickButton', {
        button: actionText,
        landingUrl: this.landingUrl,
        referrerUrl: this.referrerUrl
      });
      if (actionText === 'Uzzināt vairāk'){
        this.dialogType = 'features'
      } else if (actionText === 'Uzzināt izmaksas'){
        this.dialogType = 'price'
      }
    },
    trackClick: function(organization) {
      this.$mixpanel.track('HomePageClickOrganization', {
          organization: organization,
          landingUrl: this.landingUrl,
          referrerUrl: this.referrerUrl
      });
    },
    getImageUrl: function(identifier) {
      let logoUrl = '/img'
      const CDN_URL = process.env.VUE_APP_CDN_URL
      if (CDN_URL){
        logoUrl = CDN_URL;
      }
      return logoUrl + '/organization/' + identifier + '/logo_144.jpg'
    }
  }
}
</script>
<style scoped>
.no-webp .as-homepage-main-section {
  background-image: url('~@/assets/asport_women_with_phone.png') !important;
}
.webp .as-homepage-main-section {
  background-image: url('~@/assets/asport_women_with_phone.png') !important;
}
.as-homepage-main-section {
  color: #eee;
  background-size: cover;
  background-position: center;
  height: 250px;
}
.as-list {
  text-align: left;
}
.as-list .as-subtitle {
  display: block;
}
img.hero-img {
  max-width: 550px;
  width: 100%;
  height: auto;
}
img.logo {
  max-height: 80px;
  height: 100%;
  width: auto;
}
img.mobile-img {
  max-width: 220px;
  width: 100%;
  height: auto;
}
.gradient-dark-bottom {
  background: linear-gradient(180deg, rgba(204,204,204,0) 0%, rgba(204,204,204,0.5) 100%);
}
.veselibas-sporta-stacija a {
  color: #60b3ce !important;
}
.vingro-ar-signi a {
  color: #678c60 !important;
}
.mindfulstudioriga a {
  color: #7aa067 !important;
}
</style>
