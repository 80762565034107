<template>
  <v-row class="ma-0 pa-0 private-portal">
    <v-col cols="0" sm="2" class="pa-0 d-none d-sm-block">
      <v-navigation-drawer permanent right>
        <v-list dense v-if="myOrganization">
          <v-list-item-group>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="text-h6">
                  {{ userName }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ myOrganization.name }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              class="mt-5"
              :href="getLocalisedOrganizationUrl(myOrganization)" target="_blank"
            >
              <v-list-item-icon>
                <v-icon>mdi-application-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $t('dashboard.navigation.my-page') }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>

          <v-divider></v-divider>
          
          <profile-menu/>

        </v-list>
      </v-navigation-drawer>
    </v-col>

    <v-col cols="12" sm="10" class="pa-0">
      <v-container fluid>
        <router-view :key="$route.fullPath"/>
      </v-container>
    </v-col>
  </v-row>
</template>

<script>
import ProfileMenu from '@/components/private/layout/ProfileMenu'
import urlUtil from '@/js/utils/urlUtil'

export default {
  components: { ProfileMenu },
  data () {
    return {
    }
  },
  computed: {
    userName: function() {
      return this.$store.getters['user/name']
    },
    myOrganization: function() {
      return this.$store.getters['dashboard/myOrganization']
    },
  },
  methods: {
    ...urlUtil
  }
}
</script>