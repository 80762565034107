import OrganizationRepository from "@/js/api/OrganizationRepository"
import TClassRepository from "@/js/api/private/TClassRepository"
import TOrganizationRepository from "@/js/api/private/TOrganizationRepository"
import commonUtil from "@/js/utils/commonUtil"

const getDefaultState = () => {
  return {
    currentOrganization: null,
    myClasses: null,
    myEvents: null
  }
}

export default {
  namespaced: true,
  state: getDefaultState(),

  mutations: {
    resetState (state) {
      Object.assign(state, getDefaultState())
    },
    setClasses (state, myClasses) {
      state.myClasses = myClasses
    },
    updateClass (state, updatedClass) {
      if (!updatedClass) {
        return
      }

      updatedClass = commonUtil.clone(updatedClass)
      const item = state.myClasses.find(item => item.id === updatedClass.id)
      Object.assign(item, updatedClass)
    },
    addClass (state, newClass) {
      if (!newClass) {
        return
      }

      state.myClasses.push(newClass)
    },
    setMyEvents (state, myEvents) {
      state.myEvents = myEvents
    },
    addEvents (state, myEvents) {
      state.myEvents = state.myEvents.concat(myEvents)
    },
    updateEvent (state, updatedEvent) {
      if (!updatedEvent) {
        return
      }

      updatedEvent = commonUtil.clone(updatedEvent)
      const item = state.myEvents.find(item => item.id === updatedEvent.id)
      Object.assign(item, updatedEvent)
    },
    setCurrentOrganization (state, organization){
      state.currentOrganization = {
        id: organization.id,
        name: organization.name,
        identifier: organization.identifier,
        layout: organization.layout_style,
        type: organization.organization_type
      }
    }
  },

  actions: {
    resetState ({ commit }) {
      commit('resetState')
    },

    async loadMyClasses ({ commit, getters }, isForce) {
      if (!isForce){
        let myClasses = getters.myClasses
        if (myClasses){
          return
        }
      }

      await TClassRepository.getAll()
        .then(myClasses => {
          commit('setClasses', myClasses)
        })
        .catch(error => {
          throw error;
        })
    },
    updateClass({ commit }, myClass){
      commit('updateClass', myClass)
    },
    addClass({ commit }, newClass){
      commit('addClass', newClass)
    },
    setMyEvents ({ commit }, myEvents){
      commit('setMyEvents', myEvents)
    },
    addEvents ({ commit }, newEvents){
      commit('addEvents', newEvents)
    },
    updateEvent({ commit }, myEvent){
      commit('updateEvent', myEvent)
    },
    async setCurrentOrganizationById({ commit }, organizationId){
      await TOrganizationRepository.get(organizationId)
      .then(organization => {
        commit('setCurrentOrganization', organization)
      })
      .catch(error => {
        throw error
      })
    }
  },
  getters: {
    myOrganization: state => state.currentOrganization,
    isOrganization: state => state.currentOrganization && state.currentOrganization.type === 'organization',
    isCardView: state => state.currentOrganization && state.currentOrganization.layout === OrganizationRepository.LAYOUT_STYLE_CARD,
    isWeekView: state => state.currentOrganization && state.currentOrganization.layout === OrganizationRepository.LAYOUT_STYLE_WEEK,
    myClasses: state => state.myClasses,
    myEvents: state => state.myEvents,
    eventById: state => (id) => {
      if (!id) {
        return null
      }

      id = parseInt(id)
      const myEventList = state.myEvents.filter(myEvent => myEvent.id === id)
      if (myEventList) {
        return myEventList[0]
      } else {
        return null
      }
    },
    classById: state => (id) => {
      if (!id) {
        return null
      }

      id = parseInt(id)
      const myClassesList = state.myClasses.filter(myClass => myClass.id === id)
      if (myClassesList) {
        return myClassesList[0]
      } else {
        return null
      }
    },
  }
}
