import store from '@/store/store'
import router from '@/router'
import UserRepository from '@/js/api/UserRepository'

const getDefaultState = () => {
  return {
    token: null,
    user: null,
  }
}

export default {
  namespaced: true,
  state: getDefaultState(),

  mutations: {
    resetState (state) {
      Object.assign(state, getDefaultState())
    },
    setUser (state, payload) {
      state.token = payload.token
      state.user = {}
      state.user.id = payload.id
      state.user.isTrainer = payload.is_trainer
      state.user.isParticipant = payload.is_participant
    },
    setUserDetails (state, user) {
      state.user.email = user.email
      state.user.name = user.name
    },
    logout (state) {
      state.token = null
      state.user = null
    }
  },

  actions: {
    resetState ({ commit }) {
      commit('resetState')
    },

    changeUserDetails ({ commit }, user) {
      commit('setUserDetails', user)
    },

    async authorize ({ commit }, userPayload) {
      commit('setUser', userPayload)
      commit('setUserDetails', userPayload)
      await store.dispatch('dashboard/setCurrentOrganizationById', userPayload.default_organization_id)
    },

    refreshUserData ({ commit }) {
      UserRepository.getMe()
      .then((user) => {
        commit('setUserDetails', user)
      })
      .catch((error) => {
        console.log('Refresh user error:', error)
        throw error
      })
      
    },

    logout () {
      store.dispatch('user/removeLoggedInUserData')
      store.dispatch('dashboard/resetState')
    },
    
    removeLoggedInUserData ({ commit }) {
      commit('logout')
      router.go()
    }
  },
  getters: {
    token: state => state.token,
    user: state => state.user,
    userId: state => state.user ? state.user.id : null,
    isLoggedIn: state => !!state.token,
    name: state => state.user ? state.user.name : null,
    mixpanelUser: state => {
      if (state.user) {
        return state.user.id
      }
      let userEmail = localStorage.getItem('join-email')
      if (userEmail){
        return userEmail
      }
      return null
    }
  }
}