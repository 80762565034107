import moment from 'moment'
import 'moment-timezone'
import i18n from '@/i18n'

const timezone = 'Europe/Riga';

export default {
    /** Date to String formatting */
    formatDateForSystem(date){
      return moment(date).tz(timezone).format('yyyy-MM-DD');
    },
    formatDateTimeForSystem(date){
      return moment(date).tz(timezone).format('yyyy-MM-DDTHH:mm');
    },
    formatDate(date){
      return moment(date).tz(timezone).format('yyyy.MM.DD');
    },
    formatDateTime(date){
      return moment(date).tz(timezone).format('yyyy.MM.DD HH:mm');
    },
    formatShortDate (date) {
      return moment(date).tz(timezone).format('DD.MM');
    },
    formatShortDateTime (date) {
      return moment(date).tz(timezone).format('DD.MM HH:mm');
    },
    formatTime (date) {
      let time = moment(date).tz(timezone).format('H:mm');
      return time
    },

    /** String to Date formatting */
    toDateFromFormat(date, format){
      return moment(date, format).tz(timezone).toDate();
    },
    toDateFromFullDate(fullDateStr){
      return moment(fullDateStr, 'YYYY-MM-DDTHH:mm').tz(timezone).toDate();
    },
    toDateFromDateTime(dateStr, timeStr){
      return moment(dateStr + 'T' + timeStr, 'YYYY-MM-DDTHH:mm').tz(timezone).toDate()
    },

    /** Date modification */
    getNow(){
      return moment().tz(timezone).toDate();
    },
    addDays(date, daysToAdd){
      return moment(date).tz(timezone).add(daysToAdd, 'days').toDate();
    },
    addMonth(date, monthsToAdd){
      return moment(date).tz(timezone).add(monthsToAdd, 'months').toDate();
    },
    set(date, year, month, day){
      return moment(date).tz(timezone)
        .set("date", day)
        .set("months", month)
        .set("year", year)
        .toDate()
    },

    truncToMonth(date){
      return moment(date).tz(timezone).set("date", 1).toDate(); 
    },

    /** Date utilities */
    getNameOfDay(date){
      //Date should be of type date
      if (!date){
        return '';
      }
      let day = moment(date).tz(timezone).day();
      let translate = 'common.date.day-' + day
      let name = i18n.t(translate)
      if (name === translate){
        return ''
      }
      return name
    }
}